var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "cartao" },
    [
      _c("v-card-title", { staticClass: "text-h5 mb-4" }, [
        _vm._v("Redefinir Senha"),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              label: "Senha atual *",
                              maxlength: "20",
                              "append-icon": _vm.showOldPassword
                                ? "mdi-eye"
                                : "mdi-eye-off",
                              type: _vm.showOldPassword ? "text" : "password",
                              rules: _vm.rules.oldPassword,
                              outlined: "",
                            },
                            on: {
                              "click:append": function ($event) {
                                _vm.showOldPassword = !_vm.showOldPassword
                              },
                              input: _vm.changeInput,
                            },
                            model: {
                              value: _vm.form.oldPassword,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "oldPassword", $$v)
                              },
                              expression: "form.oldPassword",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { sm: "6" } },
                        [
                          _c("v-text-field", {
                            ref: "passwordFieldOne",
                            attrs: {
                              dense: "",
                              label: "Nova senha *",
                              counter: 20,
                              maxlength: "20",
                              "append-icon": _vm.showConfirmPassword
                                ? "mdi-eye"
                                : "mdi-eye-off",
                              type: _vm.showConfirmPassword
                                ? "text"
                                : "password",
                              rules: [
                                _vm.rules.passwordRules,
                                _vm.rules.password8Rules,
                                _vm.rules.passwordNumberRules,
                                _vm.rules.passwordUpperCaseRules,
                                _vm.rules.passwordLowercaseCaseRules,
                                _vm.rules.passwordSpecialCharacterRules,
                                _vm.rules.passwordAlreadyUsed,
                              ],
                              outlined: "",
                              disabled:
                                _vm.form.oldPassword == undefined ||
                                _vm.form.oldPassword == "",
                              "hide-details": "true",
                            },
                            on: {
                              "click:append": function ($event) {
                                _vm.showConfirmPassword =
                                  !_vm.showConfirmPassword
                              },
                              change: function ($event) {
                                return _vm.updatePassword()
                              },
                              input: function ($event) {
                                return _vm.matchPassword(true)
                              },
                            },
                            model: {
                              value: _vm.form.newPassword,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "newPassword", $$v)
                              },
                              expression: "form.newPassword",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              label: "Confirme sua nova senha *",
                              "append-icon": _vm.showConfirmNewPassword
                                ? "mdi-eye"
                                : "mdi-eye-off",
                              type: _vm.showConfirmNewPassword
                                ? "text"
                                : "password",
                              disabled:
                                _vm.form.oldPassword == undefined ||
                                _vm.form.oldPassword == "",
                              "error-messages": _vm.passwordConfirmMessage,
                              maxlength: "20",
                              outlined: "",
                            },
                            on: {
                              "click:append": function ($event) {
                                _vm.showConfirmNewPassword =
                                  !_vm.showConfirmNewPassword
                              },
                              input: function ($event) {
                                return _vm.matchPassword()
                              },
                            },
                            model: {
                              value: _vm.form.newPasswordConfirmation,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "newPasswordConfirmation",
                                  $$v
                                )
                              },
                              expression: "form.newPasswordConfirmation",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showErrorPassword,
                          expression: "showErrorPassword",
                        },
                      ],
                      staticStyle: {
                        position: "relative",
                        padding: "0px 0px 0px 0px",
                        top: "-29px",
                      },
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "my-0 py-0", attrs: { cols: "6" } },
                        _vm._l(_vm.validations, function (item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "password-validation-messages",
                              class: {
                                "password-validation-messages--error":
                                  !item.isValid,
                              },
                              attrs: { id: item.id },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "password-validation-icons",
                                  attrs: { id: item.id + "-button", small: "" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        !item.isValid
                                          ? "mdi-close-circle-outline"
                                          : "mdi-check-circle-outline"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("span", [_vm._v(_vm._s(item.text))]),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c(
                        "v-col",
                        { staticClass: "my-0 py-0", attrs: { cols: "6" } },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isPasswordConfirmationValid,
                                  expression: "!isPasswordConfirmationValid",
                                },
                              ],
                              staticClass:
                                "password-validation-messages password-validation-messages--error",
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "password-validation-icons",
                                  attrs: { small: "" },
                                },
                                [_vm._v(" mdi-close-circle-outline ")]
                              ),
                              _vm._v(" Senhas não coincidem "),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mx-0" },
                    [
                      _c("v-col", { staticClass: "px-0" }, [
                        _c("div", { staticClass: "message-obs pt-1" }, [
                          _c("p", [
                            _vm._v("* Campos de preenchimento obrigatório"),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "btn-ag-primary",
              attrs: { disabled: !_vm.valid || _vm.noChange },
              on: { click: _vm.save },
            },
            [_vm._v("Salvar")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }