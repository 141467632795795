<template>
  <v-card class="cartao">
    <v-card-title class="text-h5 mb-4">Redefinir Senha</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <v-row>
            <v-col sm="6">
              <v-text-field
                v-model="form.oldPassword"
                dense
                label="Senha atual *"
                maxlength="20"
                :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showOldPassword ? 'text' : 'password'"
                :rules="rules.oldPassword"
                outlined
                @click:append="showOldPassword = !showOldPassword"
                @input="changeInput"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6">
              <v-text-field
                ref="passwordFieldOne"
                v-model="form.newPassword"
                dense
                label="Nova senha *"
                :counter="20"
                maxlength="20"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirmPassword ? 'text' : 'password'"
                :rules="[
                  rules.passwordRules,
                  rules.password8Rules,
                  rules.passwordNumberRules,
                  rules.passwordUpperCaseRules,
                  rules.passwordLowercaseCaseRules,
                  rules.passwordSpecialCharacterRules,
                  rules.passwordAlreadyUsed,
                ]"
                outlined
                :disabled="form.oldPassword == undefined || form.oldPassword == ''"
                hide-details="true"
                @click:append="showConfirmPassword = !showConfirmPassword"
                @change="updatePassword()"
                @input="matchPassword(true)"
              />
            </v-col>
            <v-col sm="6">
              <v-text-field
                v-model="form.newPasswordConfirmation"
                dense
                label="Confirme sua nova senha *"
                :append-icon="showConfirmNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirmNewPassword ? 'text' : 'password'"
                :disabled="form.oldPassword == undefined || form.oldPassword == ''"
                :error-messages="passwordConfirmMessage"
                maxlength="20"
                outlined
                @click:append="showConfirmNewPassword = !showConfirmNewPassword"
                @input="matchPassword()"
              />
            </v-col>
          </v-row>
          <v-row v-show="showErrorPassword" style="position: relative; padding: 0px 0px 0px 0px; top: -29px">
            <v-col cols="6" class="my-0 py-0">
              <div
                v-for="item in validations"
                :id="item.id"
                :key="item.id"
                :class="{ 'password-validation-messages--error': !item.isValid }"
                class="password-validation-messages"
              >
                <v-icon :id="item.id + '-button'" small class="password-validation-icons">
                  {{ !item.isValid ? 'mdi-close-circle-outline' : 'mdi-check-circle-outline' }}
                </v-icon>
                <span>{{ item.text }}</span>
              </div>
            </v-col>
            <v-col cols="6" class="my-0 py-0">
              <div
                v-show="!isPasswordConfirmationValid"
                class="password-validation-messages password-validation-messages--error"
              >
                <v-icon small class="password-validation-icons"> mdi-close-circle-outline </v-icon>
                Senhas não coincidem
              </div>
            </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-col class="px-0">
              <div class="message-obs pt-1">
                <p>* Campos de preenchimento obrigatório</p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="btn-ag-primary" :disabled="!valid || noChange" @click="save">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import userService from '@/services/userService.js';
import { mapGetters, mapActions } from 'vuex';

import { eventBus } from '../../../main.js';

export default {
  name: 'ChangePassword',
  data: () => ({
    valid: false,
    loading: false,
    showConfirm: false,
    userForm: {},
    showOldPassword: false,
    showConfirmPassword: false,
    showConfirmNewPassword: false,
    isPasswordConfirmationValid: true,
    noChange: true,
    tfaEnabled: false,
    form: {
      oldPassword: null,
      newPassword: null,
      newPasswordConfirmation: null,
    },

    rules: {
      passwordRules: (v) => !!v || 'Preencha uma senha válida',
      password8Rules: (v) => /^(?=.*[a-zA-Z\d]).{8,20}$/.test(v) || 'De 8 a 20 caracteres',
      passwordNumberRules: (v) => /^(?=.*[0-9])/.test(v) || 'Um número de 0 a 9',
      passwordUpperCaseRules: (v) => /^(?=.*[A-Z])/.test(v) || 'Letra maiúscula',
      passwordLowercaseCaseRules: (v) => /^(?=.*[a-z])/.test(v) || 'Letra minúscula',
      passwordSpecialCharacterRules: (v) => /^(?=.*[@!#$%^&+=*()^{}~])/.test(v) || 'Um caracter especial "!@#$%"',
      passwordAlreadyUsed: () => true || this.passwordAlreadyUsedMessage,
    },

    passwordAlreadyUsedMessage: '',
    passwordConfirmMessage: '',
    showErrorPassword: false,
  }),

  computed: {
    ...mapGetters({
      validPassword: 'notifications/getValidPassword',
    }),

    validations() {
      return [
        {
          id: 'sizeChar',
          text: 'De 8 a 20 caracteres',
          isValid: /^(?=.*[a-zA-Z\d]).{8,20}$/.test(this.form.newPassword) === true,
        },
        {
          id: 'sizeNumber',
          text: 'Um número de 0 a 9',
          isValid: /^(?=.*[0-9])/.test(this.form.newPassword) === true,
        },
        {
          id: 'upper',
          text: 'Letra maiúscula',
          isValid: /^(?=.*[A-Z])/.test(this.form.newPassword) === true,
        },
        {
          id: 'lower',
          text: 'Letra minúscula',
          isValid: /^(?=.*[a-z])/.test(this.form.newPassword) === true,
        },
        {
          id: 'specialChar',
          text: 'Um caracter especial "!@#$%"',
          isValid: /^(?=.*[@!#$%^&+=*()^{}~])/.test(this.form.newPassword) === true,
        },
        {
          id: 'passwordAlready',
          text: 'Senha diferente das três últimas',
          isValid: !this.validPassword,
        },
      ];
    },

    ruleNewPasswordConfirmation() {
      return [
        (v) => !!v || 'Nova senha de confirmação é obrigatória',
        () => this.form.newPassword === this.form.newPasswordConfirmation || 'As senhas devem ser iguais',
      ];
    },
  },

  async mounted() {
    this.userForm = await this.getUserInfo();
    this.tfaEnabled = this.userForm.twoFactorAuthenticationEnabled;
  },

  methods: {
    ...mapActions('notifications', ['wasLast3Passwords1']),

    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };
      eventBus.$emit('openToast', toast);
    },

    async getUserInfo() {
      let { data } = await userService.getUser();
      if (data) {
        return data;
      }
    },

    async deactive() {
      this.showConfirm = false;
      this.tfaEnabled = this.userForm.twoFactorAuthenticationEnabled = false;
      await userService.disableTwofa(this.userForm.id);
      this.showToast('Autenticação de dois fatores desativada com sucesso', 'success', 78);
    },

    async isActive() {
      if (!this.tfaEnabled) {
        this.showConfirm = this.tfaEnabled === false;
      } else {
        await userService.enableTwofa(this.userForm.id);
        this.showToast('Autenticação de dois fatores ativada com sucesso', 'success', 78);
        this.tfaEnabled = this.userForm.twoFactorAuthenticationEnabled = true;
      }
    },

    close() {
      this.showConfirm = false;
      this.userForm.twoFactorAuthenticationEnabled = false;
    },

    async save() {
      if (this.tfaEnabled != this.userForm.twoFactorAuthenticationEnabled) {
        if (this.tfaEnabled) {
          this.isActive();
        } else {
          this.deactive();
        }
      }
      if (this.validateForm() && this.form.newPassword === this.form.newPasswordConfirmation) {
        const user = { ...this.form };
        user.oldPassword = btoa(user.oldPassword);
        user.newPassword = btoa(user.newPassword);
        user.newPasswordConfirmation = btoa(user.newPasswordConfirmation);
        await userService
          .updatePassword(user)
          .then(() => {
            this.showToast('Senha redefinida com sucesso. Realize o login abaixo', 'success', 78);

            this.noChange = true;

            localStorage.removeItem('access_Token');
            localStorage.removeItem('refresh_Token');
            setTimeout(() => {
              this.$router.push('/login');
            }, '2000');
          })
          .catch((error) => {
            this.showToast(error.response.data.message, 'error', 78);
          });
      }
    },

    validateForm() {
      return (
        this.form.oldPassword != undefined &&
        this.form.newPassword != undefined &&
        this.form.newPasswordConfirmation != undefined &&
        this.form.oldPassword != '' &&
        this.form.newPassword != '' &&
        this.form.newPasswordConfirmation != ''
      );
    },

    changeInput() {
      this.noChange = false;
    },

    async updatePassword(value) {
      await this.wasLast3Passwords(value);

      const isValid = this.$refs.passwordFieldOne.validate();

      this.showErrorPassword = !isValid;
    },

    matchPassword(isNewPass) {
      if (isNewPass && !this.form.newPasswordConfirmation) {
        this.isPasswordConfirmationValid = true;
      } else if (this.form.newPassword === this.form.newPasswordConfirmation) {
        this.isPasswordConfirmationValid = true;
      } else {
        this.isPasswordConfirmationValid = false;
      }
    },

    async wasLast3Passwords(value) {
      if (value) {
        let data = {
          identificator: this.userForm.emailAddress,
          password: value,
        };
        if (this.form.oldPassword === this.form.newPassword) {
          this.passwordAlreadyUsedMessage = 'Senha diferente das três últimas';
          this.rules.passwordAlreadyUsed = true;
        } else {
          await this.wasLast3Passwords1(data).then(() => {
            if (this.validPassword == true) {
              this.passwordAlreadyUsedMessage = '';
              this.rules.passwordAlreadyUsed = false;
            } else {
              this.passwordAlreadyUsedMessage = 'Senha diferente das três últimas';
              this.rules.passwordAlreadyUsed = true;
            }
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/design/variables.scss';

.password-validation-messages {
  display: flex;
  gap: 8px;
  align-items: center;
  color: $feedback-color-success-medium;
  font-size: 12px;
  &--error {
    color: $feedback-color-error-medium;
  }
  .password-validation-icons {
    font-size: 14px;
    color: inherit;
  }
}

.message-obs {
  font-size: 14px;
  color: $neutral-color-high-medium;
}

.title-obs {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: $neutral-color-high-dark;
}

.switch-tfa >>> .v-input--switch__thumb.primary--text {
  color: $neutral-color-low-light !important;
}

.switch-tfa >>> .v-input--switch__track {
  opacity: 100 !important;
}

.switch-tfa-background >>> .v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 4px !important;
}

.green-button {
  color: $feedback-color-success-medium !important;
}

.red-button {
  color: $feedback-color-error-medium !important;
}

.switch-tfa-background {
  background-color: #f9fafb !important;
  border-radius: 4px;
}

.switch-tfa-font {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
}
</style>
